$color-red: #E4002B;
$color-gray-main: #504949;
$color-gray-1: #E8E8E8;
$color-gray-2: #B2B0B0;

$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-large-desktop: 1200px;

$max-page-width: 1600px;

