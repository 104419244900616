@use "../abstracts" as *;

.footer {
  @extend %flex-column;

  background: $color-gray-1;
  border-radius: 60px;
  box-shadow: 
  -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
  -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
  2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
  3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
  -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
  9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
  -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
  6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
  font-size: 20px;
  gap: 40px;
  margin-top: 120px;
  padding: 40px 30px;

  @media screen and (min-width: $breakpoint-tablet) {
    font-size: 24px;
    padding: 80px 60px;
  } // footer @ $breakpoint-tablet

  @media screen and (min-width: $breakpoint-desktop) {
    display: grid;
    gap: 60px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20vh;
    padding: 70px 50px;
  } // footer @ $breakpoint-desktop

  .contact {
    @extend %flex-column;

    gap: 10px;

    @media screen and (min-width: $breakpoint-desktop) {
      grid-column: 1;
      grid-row: 2;
    }
  }  // footer > contact

  .heading {
    @media screen and (min-width: $breakpoint-desktop) {
      font-size: 40px;
      grid-column: 1;
      grid-row: 1;
    } // footer > heading @ $breakpoint-desktop
  }

  .nav {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    min-width: 100%;

    @media screen and (min-width: $breakpoint-desktop) {
      gap: 60px;
      grid-column: 2;
      grid-row: span 2;
      justify-self: end;
      min-width: 0;
    } // footer > nav @ $breakpoint-desktop

    @media screen and (min-width: $max-page-width) {
      grid-template-columns: repeat(3, 200px);
    } // footer > nav @ $max-page-width

    .subnav {
      @extend %flex-column;
      .subheading {
        font-size: inherit;
        font-weight: 400;
      } // footer > nav > subnav > subheading

      .links {
        @extend %flex-column;
        gap: 10px;

        .link {
          font-size: 16px;

          &:hover {
            opacity: 0.5;
            transition: opacity 0.3s;
          }
        } // footer > nav > subnav > links > link
      } // footer > nav > subnav > links
    } // footer > nav > subnavs
  } // footer > nav
} // footer