@use "../abstracts" as *;

.chess {
  .gallery {
    display: grid;
    grid-template-columns: 1fr;
    gap: 36px;

    @media screen and (min-width: $breakpoint-tablet) {
      gap: 60px;
      grid-template-columns: 1fr 1fr;
    } // erosion > gallery @$breakpoint-tablet

    .picture {
      width: 100% !important; 

      &:last-of-type {
        @media screen and (min-width: $breakpoint-tablet) {
          grid-column: span 2;
        } // erosion > gallery > picture:first-of-type @$breakpoint-tablet
      } // erosion > gallery > picture:first-of-type
    } // erosion > gallery > picture
  } // erosion > gallery
} // erosion