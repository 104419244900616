@use "../abstracts" as *;

.header {
  height: 43px;
  position: relative;
  transition: height 0.25s ease-in-out 0.25s;

  .logo {
    animation: fade-in 1s ease-in 0.25s forwards;
    // animation: fade-in 0.25s ease-in-out forwards;
    bottom: 0;
    left: 0;
    opacity: 0;
    right: 0;
    top: 0;
  }

  .mobile {
    animation: fade-in 0.25s ease-in-out forwards;

    .button {
      animation: fade-in 0.5s ease-in 0s forwards;

      path {
        opacity: 0;

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            animation: fade-in 0.25s ease-out forwards, scaleUp 0.25s ease-out forwards;
            animation-delay: #{$i * 0.25}s;
          }
        }
      }      
    }

    .shaded {
      position: relative;
      box-shadow: none;
      

      &.nav {
        transition: box-shadow 0.25s linear;
      }

      &::after {
        @extend %after-box;
        @extend %header-link-shadow;
  
        animation: fade-in 0.25s ease-in 0.5s forwards;
        border-radius: 50px;
        opacity: 0;
        transition: box-shadow 0.5s linear;
      }

  
      // &:hover {
      //   @extend %hover-shadow;
  
      //   transition: box-shadow 0.5s linear;
  
      //   &::after {
      //     animation: fade-out 0.1s ease-in forwards;
      //   }
      // }

      &.nav {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  

  .link, .nav-link {
    &.active {
      animation: scale-up 0.5s ease-in-out;
      opacity: 1;
    }

    &:not(.active) {
      position: relative;

      @media screen and (max-width: $breakpoint-tablet) {
        box-shadow: none;
      }    
      

 // header .nav-link::after

      &:hover {
        @extend %hover-shadow;

        border: none !important;
        transition: box-shadow 0.5s linear;

        &::after {
          animation: fade-out 0.1s ease-in forwards;
        }
      } // .header .nav-link::hover::after
    } // .header .nav-link:not(.active)
  } // .header .nav-link

  // .logo {
    
  //   bottom: 0;
  //   left: 0;
  //   opacity: 0;
  //   right: 0;
  //   top: 0;
  // }

  &.active-mobile-menu {
    padding: 100px 0; 

    .close {
      animation: fade-in 0.5s ease-in 1s forwards;
      opacity: 0;
    }

    .mobile {
      animation: fade-in 1s ease-in 0.5s forwards;
      opacity: 0;
      width: 100%;

      .button.shaded {
        animation: fade-out 0.2s ease-in 0s forwards;
        display: block;
        opacity: 1;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 2;
      }  
    }

    @keyframes logo-mobile-move {
      0% {
        opacity: 1;
      }
      10% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .logo {
      animation: logo-mobile-move 2s ease-in 0s forwards;
      bottom: 50px;
      display: block;
      left: calc(100% - 110px);
      position: absolute;
      // right: 50px;
      top: calc(100% - 80px);
      // bottom: 50px;
      transition: left 0s ease-in-out 0.5s, top 0s ease-in-out 0.5s;
      width: 60px;
      z-index: 2;
    }

    .link {
      opacity: 0;
      z-index: 9;

      &:nth-child(1) {
        animation: fade-in 0.25s ease-out 0.75s forwards, scale-up 0.5s ease-out 0.75s forwards;
        // animation-delay: #{$i * 0.75}s;
      }

      &:nth-child(2) {
        animation: fade-in 0.25s ease-out 1s forwards, scale-up 0.5s ease-out 1s forwards;
        // animation-delay: #{$i * 0.75}s;
      }

      &:nth-child(3) {
        animation: fade-in 0.25s ease-out 1.25s forwards, scale-up 0.5s ease-out 1.25s forwards;
        // animation-delay: #{$i * 0.75}s;
      }

      // @for $i from 1 through 10 {
      //   &:nth-child(#{$i}) {
      //     animation: fadeIn 0.5s ease-out 1s forwards, scaleUp 0.5s ease-out s forwards;
      //     // animation-delay: #{$i * 0.75}s;
      //   }
      // }
    }
  }
}