@use "./abstracts" as *;
@use "partials";
@use "projects";
@use "animations";



body {
  background: $color-gray-1;
  color: $color-gray-main;
  cursor: url(/images/pointer.svg), url(/images/pointer.png), auto;
  font-family: neue-haas-unica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1;    
  margin: 0;
  padding: 50px 8%;
  text-transform: lowercase;

  @media screen and (min-width: $breakpoint-tablet) {
    padding: 105px 65px;
  }
}


a,
button {
  color: $color-gray-main;
  cursor: url(/images/hand.svg), url(/images/hand.png), auto;
  text-decoration: none;
}

label {
  cursor: url(/images/pointer.svg), url(/images/pointer.png), auto;
}

input,
textarea {
  cursor: url(/images/hand.svg), url(/images/hand.png), auto !important;
}

input {
  font-family: neue-haas-unica, sans-serif;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0;
}

p {
  line-height: 1.25;
  margin: 0;
}

textarea {
  font-family: neue-haas-unica, sans-serif;
}

ul {
  list-style: none;
  padding: 0;
}
