@use "../abstracts" as *;

.services {
  .content {
    display: grid;
    gap: 48px;
    grid-template-columns: 1fr;
    justify-content: center;
    padding: 60px 10px;  

    @media screen and (min-width: $breakpoint-tablet) {
      gap: 80px;
      padding: 80px;
    } // services > content @ $breakpoint-tablet

    @media screen and (min-width: $breakpoint-desktop) {
      gap: 60px;
      grid-template-columns: 1fr 1fr;
      min-height: 40vh;
      padding: 90px;  
    } // services > content @ $breakpoint-desktop
  } // services > content

  .door {
    @extend %flex-column;

    align-items: center;
    border-radius: 24px;
    box-shadow: 
      inset -6px -6px 12px 0px rgba(255, 255, 255, 0.30), 
      -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
      2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
      3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
      -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
      9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
      inset -1px -1px 2px 0px rgba(255, 255, 255, 0.15), 
      inset 6px 6px 24px 0px rgba(0, 0, 0, 0.04);
    font-size: 28px;
    height: 20vh;
    justify-content: center;
    justify-self: center;
    width: 100%;

    @media screen and (min-width: $breakpoint-desktop) {
      align-self: center;
      border-radius: 34px;
      font-size: 32px;
      height: 40vw;
      width: 25vw;
      max-width: 300px;
      max-height: 600px;
    } // services > door @ $breakpoint-desktop

    @media screen and (min-width: 2000px) {
      max-width: 330px;
      max-height: 650px;
    }

    &:nth-of-type(1) {
      justify-self: end;
    } // services > door &:nth-of-type(1)

    &:nth-of-type(2) {
      justify-self: start;
    } // services > door &:nth-of-type(3)

    &:hover {
      box-shadow: 
        inset -12px -12px 32px 0px rgba(255, 255, 255, 0.70), 
        inset 12px 12px 32px 0px rgba(0, 0, 0, 0.14), 
        12px 12px 32px 0px rgba(255, 255, 255, 0.70), 
        -12px -12px 32px 0px rgba(0, 0, 0, 0.14);
    } // services > door &:hover
  } // services > door

  .teaser {
    @extend %flex-column;

    align-items: flex-end;
    align-self: stretch;
    margin-top: 30px;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-direction: row;
      justify-content: space-between;
    } // services > teaser @ $breakpoint-tablet
    
    .heading {
      font-size: 32px;
      font-weight: 400;
      line-height: 36px; /* 112.5% */
    } // services > teaser > heading

    .copy {
      font-size: 22px;
      text-align: right;
    } // services > teaser > copy
  } // services > teaser
} // services


.service {
  .content {
    display: grid;
    gap: 60px;
    grid-template-columns: 1fr;
    padding-bottom: 120px;

    @media screen and (min-width: $breakpoint-desktop) {
      gap: 10%;
      grid-template-columns: 1fr 1fr;
      min-height: 640px;
    }

    @media screen and (min-width: $breakpoint-large-desktop) {
      gap: 5%;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: $max-page-width) {
      grid-template-columns: 1fr 1fr 1fr;
      min-height: 40vh;      
    }
  
    .intro {
      @extend %flex-column;

      gap: 24px;
      grid-row: 2;

      @media screen and (min-width: $breakpoint-desktop) {
        gap: 40px;
        grid-column: 1;
        grid-row: 1;
      }

      @media screen and (min-width: $breakpoint-large-desktop) {
        align-self: center;
        grid-column: 1;
        grid-row: 1;
      }

      .buttons {
        @extend %flex;

        align-self: stretch;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 16px;

        .button {
          @extend %button;

          font-size: 20px;
          padding: 12px 21px;
        }
      }

      .title {
        font-size: 36px;
      }
    }
    
    .description {
      @extend %flex-column;

      align-self: stretch;
      font-size: 22px;
      grid-row: 3;

      @media screen and (min-width: $breakpoint-desktop) {
        grid-column: 1;
        grid-row: 2;
      }

      @media screen and (min-width: $breakpoint-large-desktop) {
        align-self: center;
        grid-row: 1;
        grid-column: 3;
      }
    }

    .graphic {
      @extend %flex;

      border-radius: 90px;
      box-shadow: 
        0px 30px 64px 0px rgba(149, 149, 149, 0.10), 
        0px 1px 2px 0px rgba(80, 73, 73, 0.15) inset;
      grid-row: 1;
      height: 180px;
      justify-self: center;
      padding: 15%;
      width: 180px;

      @media screen and (min-width: $breakpoint-desktop) {
        grid-column: 2;
        grid-row: 1 / span 2;
        height: 250px;
        padding: 70px 85px;
        width: 250px;
      }

      @media screen and (min-width: $breakpoint-large-desktop) {
        align-self: center;
        grid-column: 2;
        grid-row: 1;
      }

      @media screen and (min-width: $max-page-width) {
        height: 350px;
        width: 350px;
      }

      .vector {
        width: 100%;
        height: 100%;
      }
    }
  }

  .aside {
    min-height: 110px;

    @media screen and (min-width: $breakpoint-desktop) {
      min-height: 115px;
    } // service > aside @ $breakpoint-desktop

    .container {
      @extend %flex;
      @extend %page-width;
      
      bottom: 0;
      gap: 4%;
      grid-template-columns: 1fr;
      justify-content: center;
      left: 50%;
      padding: 0 15px;
      position: fixed;    
      transform: translateX(-50%);

      &.fly {
        bottom: 30px;

        @media screen and (min-width: $breakpoint-desktop) {
          width: calc(100% - 130px);
        } // service > aside > container.fly @ $breakpoint-desktop

        .shaded {
          box-shadow: 
            -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
            -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
            2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
            3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
            -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
            9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
            -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
            6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset; 
        }
      } // service > aside > container.fly
      

      &.land {
        position: static;
        transform: translateX(0%);

        .shaded {
          box-shadow: 
            -12px -12px 32px 0px rgba(0, 0, 0, 0.14) inset, 
            12px 12px 32px 0px rgba(255, 255, 255, 0.70) inset, 
            12px 12px 32px 0px rgba(0, 0, 0, 0.14), 
            -12px -12px 32px 0px rgba(255, 255, 255, 0.70);
          }
      } // service > aside > container.landed

      @media screen and (min-width: $breakpoint-desktop) {
        display: grid;
        gap: 4%;
        grid-template-columns: 1fr 0.4fr;
      }

      @media screen and (min-width: $breakpoint-large-desktop) {
        gap: 8%;
      }

      .nav {
        padding: 0 30px;
        
        .landed & {
          animation:  fadeIn 1s linear forwards;
          box-shadow: none;
          transition: width 0.5s linear;   

          &::after {
            @extend %after-box;

            animation:  fadeIn 1s linear forwards;
            border-radius: 50px;
            padding: 0;
          }
        }
    
        .links {
          @extend %flex;
    
          gap: 10px;
          position: relative;
          width: 205px;
          min-height: 62px;
    
          @media screen and (min-width: $breakpoint-desktop) {
            justify-content: end;
          
            width: 234px;
            // gap: 24px;
          } // service > aside > container > nav > links @ $breakpoint-desktop
    
          .link {
            @extend %button;
            @extend %flex;

            cursor: url(/images/hand.svg), url(/images/hand.png), auto;    
            height: 60px;
            justify-content: center;
            gap: 10px;
            width: 60px;
            z-index: 1;

            // &:nth-of-type(1) {
            //   position: absolute;
            //   top: 50%;
            //   left: 0;
            //   transform: translate(0, -50%);
            // }

            &:nth-of-type(1) {
              position: absolute;
              top: 50%;
              left: 30%;
              transform: translate(-50%, -50%);

              @media screen and (min-width: $breakpoint-desktop) {
                left: 50%;

              }
            }
            

            &:nth-of-type(2) {
              position: absolute;
              top: 50%;
              right: 30%;
              transform: translate(50%, -50%);

              @media screen and (min-width: $breakpoint-desktop) {
                right: 0;
                transform: translate(0, -50%);
              }
            }
            
            .icon {
              @extend %flex;
              
              .vector {
                fill: $color-gray-main;
              }
            } // service > aside > container > nav > links > link > icon

            &:not(.active) {
              &:hover {
                @extend %hover-shadow;
  
                border: none;
                min-height: 62px;
                min-width: 62px;
                transition: box-shadow 0.25s linear;
              } // service > aside > container > nav > links > link:hover

            }
    
            
    
            &.active {
              background: $color-red;
              border-color: transparent;
              box-shadow: 
                -2px -2px 2px 0px rgba(255, 255, 255, 0.25) inset, 
                3px 3px 3px 0px rgba(0, 0, 0, 0.25) inset;
    
              .vector {
                fill: $color-gray-1; 
              }  // service > aside > container > nav > links > link.active > vector
            } // service > aside > container > nav > links > link.active
          } // service > aside > container > nav > links > link
        } // service > aside > container > nav > links

        &.shaded {
          justify-content: space-between; 
        } // service > aside > container > nav.puffy
      } // service > aside > container > nav

      .shaded {
        @extend %flex;

        background: $color-gray-1;
        border-radius: 50px;
        font-size: 24px;
        
        @media screen and (min-width: $breakpoint-desktop) {          
          height: 110px;      
          justify-content: center; 
          padding: 0 60px 4px 60px;
        } // service > aside > container > puffy @ $breakpoint-desktop

        .fly & {
          bottom: 30px;
          box-shadow: 
            -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
            -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
            2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
            3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
            -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
            9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
            -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
            6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
          transition: box-shadow 0.5s linear, transform 0.5s linear;
          transform: scale(1);
        } // service > aside > container > shaded < fly

        &.projects {
          &:hover {
            box-shadow: 
              -6px -6px 6px 0px rgba(255, 255, 255, 0.30) inset, 
              -2px -2px 4px 0px rgba(255, 255, 255, 0.25), 
              2px 2px 5px 0px rgba(0, 0, 0, 0.05), 
              3px 3px 4px 0px rgba(0, 0, 0, 0.08), 
              -8px -8px 6px 0px rgba(255, 255, 255, 0.30), 
              9px 9px 16px 0px rgba(0, 0, 0, 0.15), 
              -1px -1px 1px 0px rgba(255, 255, 255, 0.15) inset, 
              6px 6px 12px 0px rgba(0, 0, 0, 0.04) inset;
            transition: box-shadow 0.25s linear, transform 0.25s linear;
            transform: scale(0.98);
          }
        }
      } // service > aside > container > shaded
    } // service > aside > container
  } // service > aside
} // service


