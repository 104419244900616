@use "../abstracts" as *;

.projects {
  .items {
    @extend %flex-column;

    align-items: center;
    gap: 3rem;
    justify-content: center;
    margin-top: 2rem;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-direction: row;
    }



    .item {
      border: 1px solid $color-gray-main;
      border-radius: 50px;
      height: 280px;
      width: 100%;
      max-width: 375px;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
      overflow: hidden;

      .link {
        background-color: $color-gray-1;
        position: relative;

        &:last-of-type {
          @extend %flex;

          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        }
      }

      .image {
        background-size: cover;
        background-position: center;
        border-radius: 50px;
        // filter: grayscale(100%) blur(15px);
        min-height: 100%;
        min-width: 100%;
        opacity: 0.25;
        // transition: filter 0.25s;

        &:hover {
          filter: blur(0px);
          // transition: filter 0.5s;
        }
      }

      .title {
        font-size: 1.5rem;
        position: absolute;
        top: 30px;
        left: 40px;
        width: calc(100% - 80px);
      }

      .summary {
        font-size: 1rem;
        position: absolute;
        bottom: 30px;
        left: 40px;
        letter-spacing: 0.02rem;
        width: calc(100% - 80px);
      }
    }
  }
}

.tags {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

   @media screen and (min-width: $breakpoint-tablet) {
      display: flex !important;

    }


  @media screen and (min-width: $breakpoint-tablet) {
    gap: 16px;
  }

  &.categories {
    @media screen and (min-width: $breakpoint-tablet) {
      grid-column: 3;
      grid-row: 1 / span 2;
    }
  }

  &.services {
    @media screen and (min-width: $breakpoint-tablet) {
      grid-column: 2;
      grid-row: 1 / span 2;
    }
  }

  .tag {
    align-items: center;
    
    border-radius: 50px;
    display: flex;
    font-size: 18px;;
    justify-content: center;
    padding: 10px 14px;

    @media screen and (min-width: $breakpoint-tablet) {
      padding: 12px 21px;
    }

    &.category {
      border: 1px solid $color-gray-main;
      color: $color-gray-main;

     

      
    }

    &.service {
      border: 1px solid $color-red;
      color: $color-red;
    }
  }
}


.projects {
  .card {
    @extend %flex-column;

    align-self: stretch;
    border-radius: 30px;
    border: 1px solid $color-gray-2;
    gap: 20px;
    height: auto;
    opacity: 1;
    padding: 22px 18px;
    position: relative;
    transition: height 0.25s, opacity 0.25s;
    transition-behavior: allow-discrete;

    @media screen and (min-width: $breakpoint-tablet) {
      padding: 36px 43px;
    }

    @media screen and (min-width: $breakpoint-large-desktop) {
      display: grid;
      grid-template-columns: 180px 100px 170px 1fr;
      grid-template-rows: 30px auto;      
      column-gap: 50px;
    }

    &.pending  {
      .categories, 
      .services,
      .summary {
        filter: blur(4px);
      }
    } // projects > card > pending

    .categories {
      @media screen and (min-width: $breakpoint-tablet) {
        grid-column: 3;
        grid-row: 1 / span 2;
      }
    } // projects > card > categories

    .coming-soon {
      font-size: 24px;
      margin-top: 20px;

      @media screen and (min-width: $breakpoint-desktop) {
        margin: 0;
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
      }
    } // projects > card > coming-soon

    .link {
      @extend %flex;

      align-self: flex-end;
      background: $color-gray-1;
      border-radius: 70px;
      box-shadow: 
        -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
        -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
        2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
        3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
        -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
        9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
        -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
        6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
      font-size: 24px;
      gap: 10px;
      height: 63px;
      justify-content: center;
      margin-top: 20px;
      width: 63px;

      @media screen and (min-width: $breakpoint-tablet) {
        grid-column: 4;
        grid-row: 2;
        height: auto;
        justify-self: end;
        padding: 28px;
        width: auto;        
      } // projects > card > link @ $breakpoint-tablet

      &:hover {
        box-shadow: 
          -3px -3px 8px 0px rgba(255, 255, 255, 0.70) inset, 
          3px 3px 8px 0px rgba(0, 0, 0, 0.14) inset, 
          3px 3px 8px 0px rgba(255, 255, 255, 0.70), 
          -3px -3px 8px 0px rgba(0, 0, 0, 0.14);
      } // projects > card > link:hover

      .vector {
        width: 9px;
      } // projects > card > link > vector
    } // projects > card > link

    .services {
      @media screen and (min-width: $breakpoint-tablet) {
        grid-column: 2;
        grid-row: 1 / span 2;
      } // projects > card > services @ $breakpoint-tablet
    } // projects > card > services
    
    .summary {
      font-size: 18px;

      @media screen and (min-width: $breakpoint-tablet) {
        grid-column: 1;
        grid-row: 2;
      } // projects > card > summary @ $breakpoint-tablet
    } // projects > card > summary

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 20px; /* 83.333% */
      margin: 0;

      @media screen and (min-width: $breakpoint-tablet) {
        grid-column: 1;
        grid-row: 1;
      } // projects > card > title @ $breakpoint-tablet
    } // projects > card > title 
  } // projects > card

  .content {
    @extend %flex-column;
    @extend %page-width;

    gap: 36px;

    @media screen and (min-width: $breakpoint-tablet) {
      gap: 47px;
      padding-top: 60px;
    } // projects > content @ $breakpoint-tablet
  } // projects > content

  .filter {
    @extend %flex;
    @extend %page-width;

    display: none;

    @media screen and (min-width: $breakpoint-tablet) {
      border-radius: 46px;
      box-shadow: 
        -12px -12px 32px 0px rgba(0, 0, 0, 0.14) inset, 
        12px 12px 32px 0px rgba(255, 255, 255, 0.70) inset, 
        12px 12px 32px 0px rgba(0, 0, 0, 0.14), 
        -12px -12px 32px 0px rgba(255, 255, 255, 0.70);
      display: flex;
      font-size: 24px;
      gap: 45px;
      height: 90px;
      margin: 60px auto 12px auto;
      padding: 20px 60px;
    } // projects > filter @ $breakpoint-tablet

    .input {
      @extend %flex;

      gap: 20px;
    }

    .radio {
      -webkit-appearance: none;
      appearance: none;
      background-color: $color-gray-1;
      border: 1px solid $color-gray-main;
      border-radius: 50px;
      box-shadow: none;
      cursor: url(/images/hand.svg), url(/images/hand.png), auto;
      height: 58px;
      width: 58px;
      transition: background-color 0.5s, border-color 0.25s, box-shadow 0.25s;

      &:checked {
        background-color: $color-red;
        border-color: transparent;
        box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.25) inset, 3px 3px 3px 0px rgba(0, 0, 0, 0.25) inset;
        transition: background-color 0.5s, border-color 0.25s, box-shadow 0.25s;
        
      } // projects > filter > radio:checked
    }

    .heading {
      font-weight: 400;
      margin-right: 45px;
      position: relative;

      &::after {
        background-color: $color-gray-main;
        content: "";
        display: block;
        height: 70px;
        width: 1px;
        position: absolute;
        right: -45px;
        top: -20px;
      } // projects > filter > heading::after
    } // projects > filter > heading

    .vector {
      flex-shrink: 0;
      height: 58px;
      width: 58px;
    } // projects > filter > vector
  } // projects > filter
} // projects

  



.project {
  main {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 36px;
  max-width: 1440px;
  margin: 0 auto;

    @media screen and (min-width: $breakpoint-tablet) {
      gap: 60px;
      margin-top: 90px;
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: flex-start;
align-self: stretch;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-direction: row;
      justify-content: space-between;
      gap: 60px;
    }

    .title {
      color: $color-gray-main;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      line-height: 1.1; /* 83.333% */
      text-transform: lowercase;

      @media screen and (min-width: $breakpoint-tablet) {
        font-size: 36px;
      }

      @media screen and (min-width: $breakpoint-large-desktop) {
        font-size: 48px;
      }
    }
  }

  .outro {
    display: flex;
    flex-direction: column;
    gap: 36px;
    min-width: 100%;

    .copy {
      @media screen and (min-width: $breakpoint-tablet) {
        gap: 60px;
        flex-direction: row;
        justify-content: space-between;
        align-self: stretch
        
      }

      p {
        @media screen and (min-width: $breakpoint-tablet) {
          width: 50%;
          
        }

      }
    }

    .button {
      align-self: flex-start;
      display: inline-flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      border: 1px solid $color-gray-main;
      background: $color-gray-1;
      text-decoration: none;
      font-size: 16px;

      @media screen and (min-width: $breakpoint-tablet) {

        align-self: flex-end;
      }

    }
  }

  .copy {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }

  .gallery {
    display: flex;
    flex-direction: column;
    gap: 36px;
    min-width: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-direction: row;
    }

    @media screen and (min-width: $breakpoint-large-desktop) {
      gap: 60px;
    }

    img {
      @media screen and (min-width: $breakpoint-tablet) {
        min-height: 100%;
      }
    }
  }

  .feature-image {
    .landscape img {
      height: 50vh;
      min-width: 100%;

      @media screen and (min-width: $breakpoint-large-desktop) {
        height: auto;
      }
    }

    .portrait  img {
      height: auto;
      width: 100%;
    }
  }

  .feature-video {
    .video {
      border-radius: 50px;
      min-height: 50vh;
      object-fit: cover;
    }    
  }

  img {
    border-radius: 50px;
    display: block;
    height: 50vh;

    width: 100%;
    object-fit: cover;


    @media screen and (min-width: $breakpoint-tablet) {
      height: auto;
    }
  }

  p {
    color: var(--main, #504949);
    leading-trim: both;
    text-edge: cap;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 127.273% */
    margin: 0;
    max-width: 625px;
    text-transform: lowercase;

  }
  
}