@use "../abstracts" as *;

.home {
  .copy {
    font-size: 24px;

    @media screen and (min-width: $breakpoint-tablet) {
      font-size: 32px;
    }

    .span {
      @media screen and (min-width: $breakpoint-desktop) {
        display: block;
      }

    }
  } // .home .copy

  .content {
    padding: 60px 0;

    @media screen and (min-width: $breakpoint-tablet) {
      padding: 120px 0;
    } // .home .content .@ $breakpoint-tablet

    .heading {
      font-size: 2rem;
      font-weight: 400;
      margin-bottom: 4rem;
    } // .home .content .heading
  } // .home .content

  .cta {
    @extend %flex;

    justify-content: center;
    margin: 35vh 0 5vh 0;

    .link {
      @extend %flex;

      border-radius: 100px;
      box-shadow: 
        0px 30px 64px 0px rgba(149, 149, 149, 0.10), 
        0px 1px 2px 0px rgba(80, 73, 73, 0.15) inset;
      font-size: 1.5rem;      
      gap: 4rem;
      padding: 1.5rem 3rem;

      .device {
        @extend %flex;

        border-radius: 80px;
        box-shadow: 
          -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
          -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
          2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
          3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
          -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
          9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
          -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
          6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
        min-height: 70px;
        justify-content: center;
        min-width: 70px;

        .vector {
          width: 20px;
        }
      }      
    }
  } // .home .cta

  .intro {
    @extend %flex-column;

    gap: 1rem;
    padding: 0 36px;
    
    @media screen and (min-width: $breakpoint-tablet) {
      align-items: flex-end;
      

      > * {
        width: 60%;
      }
    } // .home .intro @ $breakpoint-tablet

    @media screen and (min-width: $breakpoint-desktop) {
      gap: 32px;
      margin-top: 120px;
    }

    .title {
      font-size: 42px;

      @media screen and (min-width: $breakpoint-desktop) {
        font-size: 48px;
      }
    }
  } // .home .intro

  .prelude {
    @extend %flex-column;

    align-items: center;
    gap: 35vh;
    margin-top: 35vh;

    @media screen and (min-width: $breakpoint-tablet) {
      gap: 36vw;
      margin: 36vw auto 18vw auto;
    } // .home .content .prelude @ $breakpoint-tablet

    .row {
      @extend %flex-column;

      // backdrop-filter: blur(14px);
      background: rgba(232, 232, 232, 0.7);
      border: 3px solid var(--background, #E8E8E8);
      border-radius: 36px;
      box-shadow: 
        0px 30px 64px 0px rgba(149, 149, 149, 0.10), 
        0px 1px 2px 0px rgba(80, 73, 73, 0.15) inset;
      gap: 1rem;
      padding: 36px;
      -webkit-transform: translateZ(0);
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;

      @media screen and (min-width: $breakpoint-desktop) {
        border-radius: 60px;
        flex-direction: row;
        justify-content: space-between;
        padding: 60px;
      } // .home .content .prelude .row @ $breakpoint-tablet

      &.fields {
        @media screen and (min-width: $breakpoint-desktop) {
          align-items: center;

          .links {
            align-self: center;
            margin-top: 0;
          }
        }
      }

      .copy {
        @extend %flex-column;
        gap: 1rem;

        @media screen and (min-width: $breakpoint-tablet) {
          gap: 2rem;
        } // .home .content .prelude .row .copy @ $breakpoint-tablet

        @media screen and (min-width: $breakpoint-desktop) {
          max-width: 38%;
        } // .home .content .prelude .row .copy @ $breakpoint-desktop
      } // .home .content .prelude .row .copy

      .links {
        align-self: end;
        margin-top: 1rem;

        .link {
          font-size: 32px;
          margin: 0 0.75rem 0 0;
          padding-right: 0.75rem;
          position: relative;

          &:not(:last-of-type) {
            &::after {
              @extend %after-box;

              background-color: $color-gray-main;
              left: auto;
              margin: 0 0.5rem 0 0;
              position: absolute;
              right: -0.5rem;
              width: 1px;
            } // .home .prelude .row .links .link::after
          } // .home .prelude .row .links .link:not(:last-of-type)
        } // .home .prelude .row .links .link
      } // .home .prelude .row .links
    } // .home .prelude .row
  } // .home .prelude

  .projects {
    .items {  
      @media screen and (min-width: $breakpoint-tablet) {
        flex-direction: column;
      }

      @media screen and (min-width: $breakpoint-desktop) {
        flex-direction: row;
      }
    }
  } // .home .projects
  
  
  .services {
    scroll-margin-top: 18vh;
    margin: 35vh 0;

    @media screen and (min-width: $breakpoint-tablet) {
      margin: 18vw 0;
    }

    .links {
      @extend %flex-column;

      align-items: center;
      gap: 3rem;
      justify-content: center;
      margin-top: 2rem;

      @media screen and (min-width: $breakpoint-tablet) {
        flex-direction: row;
      } // .home .services .links @ $breakpoint-tablet

      .link {
        @extend %flex;

        background-color: $color-gray-1;
        border-radius: 150px;
        box-shadow: 
          -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
          -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
          2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
          3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
          -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
          9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
          -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
          6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
        height: 220px;
        justify-content: center;
        width: 140px;

        @media screen and (min-width: $breakpoint-tablet) {
          height: 280px;
          width:  180px;
        } // .home .services .links .link @ $breakpoint-tablet

        @media screen and (min-width: $breakpoint-desktop) {
          height: 360px;
          width:  230px;
        }

        &:hover {
          border: none;
          box-shadow: 
            -12px -12px 32px 0px rgba(255, 255, 255, 0.70) inset, 
            12px 12px 32px 0px rgba(0, 0, 0, 0.14) inset, 
            12px 12px 32px 0px rgba(255, 255, 255, 0.70), 
            -12px -12px 32px 0px rgba(0, 0, 0, 0.14);
        } // .home .services .links .link:hover

        .vector {
          width: 80px;

          @media screen and (min-width: $breakpoint-tablet) {
            width: 100px;
          }
        } // .home .services .links .link .vector
      } // .home .services .links .link
    } // .home .services .links
  } //  .home .services

  .topography {
    left: 0;
    min-height: 240vh;
    max-width: 100vw;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: -1;

    @media screen and (min-width: $breakpoint-desktop) {
      min-height: 400vh;
    } // .home .topography @ $breakpoint-tablet

    &-left {
      left: 0;
      opacity: 1;
      top: 60vh;
      transform: translate(-60%, 0);      
      
      .path {
        stroke: black;
        stroke-width: 0;
        fill: #E8E8E8;
      } // .home .topography .topography-left .path

      @media screen and (min-width: $breakpoint-tablet) {
        top: 0;
        transform: translate(-65%, 0);
      } // .home .topography .topography-left @ $breakpoint-tablet

      @media screen and (min-width: 1400px) {
        transform: translate(-50%, 0);
      } // .home .topography .topography-right @ $breakpoint-desktop 
    }  // .home .topography .topography-left

    &-right {
      right: 0;
      top: 20vh;
      transform: translate(60%, 0);

      @media screen and (min-width: $breakpoint-tablet) {
        top: 250px;
        transform: translate(65%, 0);
      } // .home .topography .topography-right @ $breakpoint-tablet

      @media screen and (min-width: 1400px) {
        transform: translate(50%, 0);
      } // .home .topography .topography-right @ $breakpoint-desktop 
    } // .home .topography .topography-right

    .device {
      height: 100vh;
      overflow: hidden;
      position: absolute;

      @media screen and (min-width: $breakpoint-tablet) {
        height: 150vh;
      } // .home .topography .device @ $breakpoint-tablet

      @media screen and (min-width: $breakpoint-desktop) {
        height: 190vh;
      } // .home .topography .device @ $breakpoint-large-desktop

      @media screen and (min-width: 1600px) {
        height: 200vh;
      } // .home .topography .device @ $breakpoint-large-desktop
    }  // .home .topography .device 
  } // .home .topography 
} // .home