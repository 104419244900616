@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-out-in {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes scale-down {
  from {
    transform: scale(100%);
  }
  to {
    transform: scale(0%);
  }
}

@keyframes grow {
  from {
    transform: scale(1, 0);
  }
  to {
    transform: scale(1, 1);
  }
}

@keyframes scale-up {
  from {
    transform: scale(98%);
  }
  to {
    transform: scale(100%);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes border-out {
  to {
    border: 0px solid transparent
  }
}

@keyframes topo {
  0% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    stroke-width: 0;
  }
  50% {
    stroke-dasharray: 4;
    stroke-dashoffset: 4;
    stroke-width: 1;
  }
  100% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    stroke-width: 0;

  }
}

