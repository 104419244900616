@use "../abstracts" as *;

.contact {
  .content {
    > * {
      opacity: 0;
  
      @for $i from 1 through 2 {
        &:nth-child(#{$i}) {
          animation: fade-in 0.5s ease-out forwards;
          animation-delay: #{$i * 0.15}s;
        }
      }
    }
  }

  .form {
    > * {
      opacity: 0;
      
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: fade-in 0.5s ease-out forwards;
          animation-delay: #{$i * 0.35}s;
        }
      }
    }

    .group {
      input,
      textarea {
        &:user-invalid {
          border: 1px solid $color-red;
          position: relative;
          &:after {
            content: 'Invalid';
            color: $color-red;
            font-size: 0.8rem;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        &:user-valid {
          border: none;
          box-shadow: 
            -3px -3px 8px 0px rgba(255, 255, 255, 0.7) inset, 
            3px 3px 8px 0px rgba(0, 0, 0, 0.14) inset, 
            3px 3px 8px 0px rgba(255, 255, 255, 0.7), 
            -3px -3px 8px 0px rgba(0, 0, 0, 0);
          }
          transition: border 1s ease-out, box-shadow 1s ease-out;
        

        &:checked + label {
          transition: border 1s ease-out, box-shadow 1s ease-out;
        }
      } 
    }
  }
   
}