@use "../abstracts" as *;

.contact {
  .content {
    @extend %flex-column;

      gap: 50px;
      max-width: 650px;

      @media screen and (min-width: $breakpoint-large-desktop) {
        align-items: center;
        justify-content: center;
        max-width: $max-page-width;
        min-height: 40vh;
      }
    }

  .form {
    @extend %flex-column;

    gap: 30px;
    position: relative;

    @media screen and (min-width: $breakpoint-large-desktop) {
      display: grid;
      gap: 60px;
      grid-template-columns: repeat(12, 1fr);
    }

    @media screen and (min-width: $max-page-width) {
      gap: 90px;
    }

    .group {
      @extend %flex-column;

      gap: 10px;

      @media screen and (min-width: $breakpoint-tablet) {
        grid-column: span 4;

        &:last-of-type {
          grid-column: span 8;
        }
      }

      .input {
        @extend %flex;
        @extend %button;
        
        align-self: stretch;
        background: $color-gray-1;
        border: 1px solid $color-gray-main;
        padding: 16px 24px;

        &::file-selector-button {
          background-color: $color-gray-1;
          border: none;
          color: $color-gray-main;
          cursor: url(/images/hand.svg), url(/images/hand.png), auto !important;   
          font-weight: 600;
          text-decoration: underline;
          text-transform: lowercase;
        }
      }

      &.services {
        @extend %flex-column;
        // align-self: stretch;
        // display: grid;
        // grid-template-columns: repeat(1fr);
        gap: 10px;

        .services {
          @extend %flex-column;

          height: 100%;
          gap: 10px;

          @media screen and (min-width: $breakpoint-tablet) {
            align-items: stretch;
            flex-direction: row;
            justify-content: space-evenly;
            gap: 32px;
          }
        }


        .service {
          width: 100%;

          @media screen and (min-width: $breakpoint-tablet) {
            height: 280px;
            max-width: 120px;
          }
        }

        @media screen and (min-width: $breakpoint-tablet) {
          gap: 25px;
          grid-column: span 4;
          grid-template-rows: 32px auto;
        }

        .label {
          grid-column: span 2;
        }

        .input {
          display: none;

          &:checked + label {
            border: none;
            box-shadow: -3px -3px 8px 0px rgba(255, 255, 255, 0.70) inset, 3px 3px 8px 0px rgba(0, 0, 0, 0.14) inset, 3px 3px 8px 0px rgba(255, 255, 255, 0.70), -3px -3px 8px 0px rgba(0, 0, 0, 0.14);
            height: 57px;   
            
            @media screen and (min-width: $breakpoint-tablet) {
              height: 281px;
              
            }
          }
        }
        
        .button {
          @extend %flex;
          @extend %button;

          box-shadow: none;
          cursor: url(/images/hand.svg), url(/images/hand.png), auto !important;        
          font-size: 20px;
          height: 56px;
          justify-content: center;

          @media screen and (min-width: $breakpoint-tablet) {
            height: 100%;
          }
        }
      }

      .text-area {
        @extend %button;

        align-self: stretch;
        background-color: $color-gray-1;
        border-radius: 40px;
        border: 1px solid $color-gray-main;
        min-height: 245px;
        padding: 24px;
        resize: none;
      }
    }

    .label {
      font-size: 20px;      
    }

    .submit {
      @extend %button-red;

      align-self: flex-end;
      font-size: 24px;
      padding: 16px 30px;   
      
      @media screen and (min-width: $breakpoint-tablet) {
        position: absolute;
        right: 30px;
        bottom: 30px;
      }
    }
  }

  .intro {
    @extend %flex;

    justify-content: space-between;
    gap: 10px;
    width: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
      gap: 100px;
      justify-content: flex-start;
    }

    .title {
      font-size: 30px;
    }

    .links {
      @extend %flex-column;

      justify-content: center;
      gap: 16px;

      @media screen and (min-width: $breakpoint-tablet) {
        font-size: 22px;
      }
    }
  }
}