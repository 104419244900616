@use "../abstracts" as *;

.project {
  p {
    animation: fade-in 0.5s ease-out 0.75s forwards;
    opacity: 0;

  }

  img,
  video {
    animation: fade-in 2s ease-out 1s forwards;
    opacity: 0;
  }

  .copy {
    p {     
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: fade-in 0.5s ease-out forwards;
          animation-delay: #{($i + 0.25) * 0.25}s;
        }
      } // staggered load effect
    } // .project .copy p
  } // .project .copy

  .feature-image  {
    animation: fade-in 0.5s ease-out 0.5s forwards;
    opacity: 0;
  } // .project .feature-image

  .tags {
    .tag {     
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: fade-in 0.5s ease-out forwards;
          animation-delay: #{($i + 0.25) * 0.25}s;
        }
      } // staggered load effect
    } // .project .tags .tag
  } // .project .tags

  .title {
    animation: fade-in 2s ease-out 0s forwards;
    opacity: 0;
  }
} // .project

.projects {
  .card {
    opacity: 0;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation: fade-in 0.5s ease-out forwards;
        animation-delay: #{$i * 0.25}s;
      }
    } // staggered load effect

    .link {
      box-shadow: none;
      position: relative;
      transition: box-shadow 0.5s linear;

      &:hover {
        box-shadow: 
          -3px -3px 8px 0px rgba(255, 255, 255, 0.70) inset, 
          3px 3px 8px 0px rgba(0, 0, 0, 0.14) inset, 
          3px 3px 8px 0px rgba(255, 255, 255, 0.70), 
          -3px -3px 8px 0px rgba(0, 0, 0, 0.14);
        transition: box-shadow 0.5s linear;

        &::after {
          animation: fade-out 0.1s ease-in forwards;
        } // .projects .card .link:hover::after
      } // .projects .card .link:hover

      &::after {
        @extend %after-box;

        animation: fade-in 1s ease-out 0.5s forwards;
        border-radius: 100px;
        box-shadow: 
          -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
          -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
          2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
          3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
          -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
          9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
          -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
          6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
          opacity: 0;
      } // .projects .card .link::after
    } // .projects .card .link
  } // .projects .card

  .filter {
    animation: fade-in 1s ease-out 0s forwards;
    box-shadow: none;
    position: relative;

    > * {
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: fade-in 0.25s ease-out forwards;
          animation-delay: #{$i * 0.15}s;
        }
      } // staggered load effect
    } // .projects .filter > *

    &::after {
      @extend %after-box;
      
      animation: fade-in 1s ease-out 1s forwards;
      border-radius: 46px;
      box-shadow: 
        -12px -12px 32px 0px rgba(0, 0, 0, 0.14) inset, 
        12px 12px 32px 0px rgba(255, 255, 255, 0.70) inset, 
        12px 12px 32px 0px rgba(0, 0, 0, 0.14), 
        -12px -12px 32px 0px rgba(255, 255, 255, 0.70);
      opacity: 0;
    } // .projects .filter::after

    .input {
      z-index: 1;
    }
  } // .projects .filter
} // .projects