@use "../variables" as *;

%after-box {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;   
}

%flex {
  align-items: center;
  display: flex;

  &-column {
    @extend %flex;

    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
  }
}

%grid {
  display: grid;
}

%page-width {
  margin: 0 auto;
  max-width: $max-page-width;
}