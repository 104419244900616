@use "../abstracts" as *;

.home {
  .cta {
    .link {
      .device {
        box-shadow: none;
        position: relative;

        &::after {
          @extend %after-box;

          animation: fade-in 0.25s ease-out forwards;
          border-radius: 80px;
          box-shadow: 
            -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
            -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
            2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
            3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
            -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
            9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
            -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
            6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
        }

        &:hover {
          @extend %hover-shadow;

          transition: box-shadow 1s ease-out; 
  
          &:after {
            animation: fade-out 0.25s ease-out forwards;
          }
        }  
      }

    }
    
  }

  .services {

    .link {    
      box-shadow: none;
      opacity: 0;
      position: relative;
      transition: box-shadow 0.5s ease-out;

      &:after {
        @extend %after-box;

        border-radius: 150px;
        box-shadow: 
          -6px -6px 12px 0px rgba(255, 255, 255, 0.30) inset, 
          -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
          2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
          3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
          -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
          9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
          -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
          6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
        transition: box-shadow 0.25s ease-out;
      }

      &:hover {
        transition: box-shadow 1s ease-out; 

        &:after {
          animation: fade-out 0.25s ease-out forwards;
        }
      }

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: fade-in 1s ease-out forwards;
          opacity: 0;
          animation-delay: #{$i * 0.25}s;
        }
      }
    }
  }
  
  .visible {
    // &.topography {
    //   &-left {
    //     path {
    //       opacity: 0;
  
    //       @for $i from 1 through 10 {

    //         &:nth-child(#{$i}) {
    //           animation: fade-in 0.8s ease-out forwards;
    //           animation-delay: #{$i * 0.4}s;
    //         }
    //       }
    //     }
    //   }
  
    //   &-right {
    //     path {
    //       opacity: 0;
  
    //       @for $i from 1 through 10 {

    //         &:nth-child(#{$i}) {
    //           animation: fade-in 0.8s ease-out forwards;
    //           animation-delay: #{$i * 0.4}s;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}