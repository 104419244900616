@use "../variables" as *;
@use "layout" as *;

%button {
  border: 1px solid $color-gray-main;
  border-radius: 500px;
  cursor: pointer;
  text-transform: lowercase;

  &-red {
    @extend %button;

    background-color: $color-red;
    border: 1px solid $color-red;
    color: $color-gray-1;
  }
}