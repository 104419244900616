@use "../abstracts" as *;

.footer {
  box-shadow: none; 
  position: relative;

  &::after {
    @extend %after-box;

    border-radius: 50px;
    box-shadow: 
      -6px -6px 12px 0px rgba(255, 255, 255, 0.3) inset, 
      -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
      2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
      3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
      -8px -8px 12px 0px rgba(255, 255, 255, 0.3), 
      9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
      -1px -1px 2px 0px rgba(255, 255, 255, 0.15) inset, 
      6px 6px 24px 0px rgba(0, 0, 0, 0.04) inset;
    opacity: 0;
    z-index: -1;
  }

  &.visible {
    &::after {
      animation: fade-in 1s ease-in 0s forwards;
    }
  }
}