@use "../abstracts" as *;

.services {
  .door {
    box-shadow: none;
    opacity: 0;
    position: relative;

    &:hover {
      transition: box-shadow 0.5s linear;

      &::after {
        animation: fade-out 0.1s ease-in forwards;
      } // services > door::hover::after
    } // services > door::hover

    &::after {
      @extend %after-box;

      animation: fade-in 0.25s ease-in forwards;
      border-radius: 24px;
      box-shadow: 
        inset -6px -6px 12px 0px rgba(255, 255, 255, 0.30), 
        -2px -2px 8px 0px rgba(255, 255, 255, 0.25), 
        2px 2px 10.4px 0px rgba(0, 0, 0, 0.05), 
        3px 3px 9.1px 0px rgba(0, 0, 0, 0.08), 
        -8px -8px 12px 0px rgba(255, 255, 255, 0.30), 
        9px 9px 32px 0px rgba(0, 0, 0, 0.15), 
        inset -1px -1px 2px 0px rgba(255, 255, 255, 0.15), 
        inset 6px 6px 24px 0px rgba(0, 0, 0, 0.04); 
      transition: box-shadow 0.5s linear; 
      
      @media screen and (min-width: $breakpoint-desktop) {
        border-radius: 34px;
      } // door &::after @$breakpoint-desktop
    } // door &::after

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation: fade-in 0.5s ease-out forwards, scale-up 0.25s ease-out forwards;
        animation-delay: #{$i * 0.25}s;
        opacity: 0;
      }
    } // staggered load effect
  } // services .door

  .teaser {

    > * {
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: fade-in 0.5s ease-out forwards;
          animation-delay: #{$i * 1}s;
        }
      } // staggered load effect
    } // services .teaser > *
  }
} // services

.service {
  .content {
    animation: fade-in 0.25s ease-out;

    .graphic {
      .vector {

        .path {
          opacity: 0;
          
          @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
              animation: fade-in 10ms ease-out forwards;
              animation-delay: #{$i * 20}ms;
            }
          } // staggered load effect
        } // service .content .graphic .vector .path 
  
        
      } // service .content .graphic .vector
    } // service .content .graphic
    
    .button {
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: fade-in 0.5s ease-out forwards;
          animation-delay: #{$i * 0.1}s;
        }
      } // staggered load effect
    } // service .content .button   
  } // service .content

  .aside {
    .container {
      &.fly {
        .shaded {
          transition: box-shadow 0.5s linear;
        } // service .aside .container.fly .shaded
      } // service .aside .container.fly

      &.land {
        .shaded {
          box-shadow: none;
          transition: box-shadow 0.25s linear;
          position: relative;

          &::after {
            @extend %after-box;

            animation: fade-in 0.5s ease-out 0.2s forwards;
            border-radius: 50px;
            box-shadow: 
              -12px -12px 32px 0px rgba(0, 0, 0, 0.14) inset, 
              12px 12px 32px 0px rgba(255, 255, 255, 0.70) inset, 
              12px 12px 32px 0px rgba(0, 0, 0, 0.14), 
              -12px -12px 32px 0px rgba(255, 255, 255, 0.70);
            opacity: 0;
            z-index: 0;
          } // service .aside .container.land .shaded::after
        } // service .aside .container.land .shaded

        .projects {
          &:hover {
            box-shadow: 
            -3px -3px 8px 0px rgba(255, 255, 255, 0.7) inset,
            3px 3px 8px 0px rgba(0, 0, 0, 0.14) inset,
            3px 3px 8px 0px rgba(255, 255, 255, 0.7),
            -3px -3px 8px 0px rgba(0, 0, 0, 0.14);

            &::after {
              animation: fade-out 0.25s ease-out forwards;
              opacity: 0;
            }
          }
        }
      } // service .aside .container.land

      .link {
        transition: border 0.5s linear, box-shadow 0.25s linear, height 0.25s linear, width 0.25s linear;
      }
    } // service .aside .container

    .projects {
      .link {
        animation: fade-in 0.25s ease-out forwards;
      }
    } // service .aside .projects
  } // service .aside

  &.animate-out {
    .content {
      animation: fade-out 0.25s ease-out forwards;
    } // service .animate-out .content

    .aside {
      .projects {
        animation: fade-out 0.25s ease-out forwards;
      } // service .animate-out .aside .projects
    } // service .animate-out .aside
  } // service .animate-out
} // service