@use "../abstracts" as *;

.header {
  @extend %flex;

  gap: 48px;
  justify-content: space-between;
  margin-bottom: 60px;

  @media screen and (min-width: $breakpoint-tablet) {
    justify-content: flex-start ;
    margin-bottom: 0;
  }

  nav {
    // display: none;

    @media screen and (min-width: $breakpoint-tablet) {
      display: block;
    }

    ul {
      @extend %flex;

      gap: 48px;

      li {
        display: inline-block;        
      }
    }
  }

  .contact {
    margin-left: auto;
  }

  .mobile {
    justify-self: end;
    padding-top: 0;

    .button {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      border: none;
      border-radius: 32px;
      height: 43px;
      width: 72px;
    }

    .nav {
      background: $color-gray-1;
      border-radius: 50px;
      display: none;
      gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      left: 50px;
      padding: 37px 41px;
      position: absolute;
      right: 50px;
      transition-behavior: allow-discrete;
      top: 50px;
      width: calc(100% - 82px);


      .button {
        background-color: transparent;
        box-shadow: unset;
        grid-column: 2;
        grid-row: 1;
        justify-self: end;

        &.close {
          position: absolute;
          right: 35px;
          top: 50px;
          z-index: 9;
        }
      }

      .links {
        @extend %flex-column;

        gap: 10px;
        grid-column: 1;
        grid-row: 1 / span 2;

        .link {
          @extend %button;

          padding: 14px 22px;

        }
      }
    }

    .shaded {
      @extend %header-link-shadow;
    }
  }

  &.active-mobile-menu {

    .mobile {
      flex-grow: 1;
      left: 0;
      position: absolute;  
      top: 0;
      
      > .button {
        // display: none;
      } // header.active-mobile-menu .menu > .button

      .nav {
        display: block;
      } // header.active-mobile-menu .menu .nav
    } // header.active-mobile-menu .menu
  } // header.active-mobile-menu
} // header

.nav-link {
  @extend %flex;
  @extend %header-link-shadow;

  border-radius: 50px;
  font-size: 24px;
  height: 52px;
  justify-content: center;
  line-height: 52px;
  padding: 2px 28px 6px 28px; 
  transition: box-shadow 0.5s ease-in;

  &.active {
    background: $color-red;
    color: $color-gray-1 !important;
    box-shadow: 
      -2px -2px 2px 0px rgba(255, 255, 255, 0.25) inset, 
      3px 3px 3px 0px rgba(0, 0, 0, 0.25) inset;
    
  }

  &:not(.active) {
    &:hover {
      @extend %hover-shadow;
    }
  }

  &:visited {
    color: $color-gray-main;
  }
}